import React, { Component } from 'react';
import { UncontrolledAccordion, AccordionBody, AccordionHeader, AccordionItem, Card, CardBody, CardTitle, CardSubtitle, Badge, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import './Alert.css';
import * as moment from 'moment';
import { TransitSystem } from './TransitSystem';
import { Link } from 'react-router-dom';
import RealTime from './RealTime';
import Cookies from 'js-cookie';
import authService from './api-authorization/AuthorizeService';

export class RouteAlert extends Component {

    render() {
        const noEndDateTime = '1970-01-01T00:00:00';

        const hasAlerts = this.props.routeAlerts.length;
        const routeShortName = this.props.routeId;

        if (hasAlerts === 0) {
            return (<div>There are no Alerts or Advisories at this time.</div>);
        }

        function insertSpaces(string) {
            string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
            string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            return string;
        }

        function evaluateFutureAlertDetails(alert) {
            let detailTitle = "Click for details";
            for (let index = 0; index < alert.alertPeriods.length; index++) {
                if (moment.utc(alert.alertPeriods[index].startDateTime).local().format('yyyy-MM-DDTHH:mm:ss') > moment.utc().local().format('yyyy-MM-DDTHH:mm:ss')) {
                    detailTitle = "Click for details (Future Alert)";
                    break;
                }
            }

            return detailTitle;
        }

        function evaluateFutureAlert(alert) {
            let isFuture = false;
            for (let index = 0; index < alert.alertPeriods.length; index++) {
                if (moment.utc(alert.alertPeriods[index].startDateTime).local().format("yyyy-MM-DDT00:00:01") > moment.utc().local().format("yyyy-MM-DDTHH:mm:ss")) {
                    isFuture = true;
                    return <Badge className="alertsBadgeDetailFuture" pill>{insertSpaces(alert.effectText)}</Badge>
                } else {
                    // Do Nothing
                }
            }
            if (!isFuture) {
                return <Badge className="alertsBadgeDetail" pill > {insertSpaces(alert.effectText)}</Badge>
            }
        }

        return (
            <div>
                {
                    this.props.routeAlerts.map((alert, index) =>
                        <Card key={index} className="mb-3">
                            <CardBody>
                                {/* Debugging info only on DEV*/}
                                {process.env.REACT_APP_ENV === 'Development' ? alert.gtfsentityId : <span></span>}
                                <CardTitle tag="h5">
                                    <Link to={{ pathname: 'RouteAlertsDetail/' + alert.transitSystemId + '/' + routeShortName + '/' + alert.uniqueId }} key={alert.id} title={evaluateFutureAlertDetails(alert)}>{evaluateFutureAlert(alert)}</Link>
                                </CardTitle>
                                <CardSubtitle className="mb-2 text-muted" tag="h6">
                                    <div>In Effect:</div>
                                    {!Object.is(alert.alertPeriods, null) && !Object.is(alert.alertPeriods, undefined) ?
                                        alert.alertPeriods.map((activePeriod, index3) =>
                                        (
                                            Object.is(activePeriod.endDateTime, null) || Object.is(activePeriod.endDateTime, undefined) || Object.is(activePeriod.endDateTime, noEndDateTime) ?
                                                <div key={index3}>{moment.utc(activePeriod.startDateTime).local().format('LLL')}</div> :
                                                <div key={index3}>{moment.utc(activePeriod.startDateTime).local().format('LLL')} to {moment.utc(activePeriod.endDateTime).local().format('LLL')}</div>
                                        ))
                                    : <span></span>}
                                    {!Object.is(alert.alertHeaders, null) && !Object.is(alert.alertHeaders, undefined) ? alert.alertHeaders.map((header, index5) => <div key={index5} dangerouslySetInnerHTML={{ __html: header.text }} />) : <span></span>}
                                    {!Object.is(alert.alertDescriptions, null) && !Object.is(alert.alertDescriptions, undefined) ? alert.alertDescriptions.map((description, index2) => <div key={index2} dangerouslySetInnerHTML={{ __html: description.text }} />) : <span></span>}
                                    {/*alert.alertDescriptions.map((description, index2) => <div key={index2}>{description.text}</div>)*/}
                                </CardSubtitle>
                                <div className="card-text">                                                                    
                                    {
                                        !Object.is(alert.alertEntityAffecteds, null) && !Object.is(alert.alertEntityAffecteds, undefined) ?
                                            <ul className="alertEntityAffectedsUl">
                                                {alert.alertEntityAffecteds.map((entityAffected, index4) =>
                                                (
                                                    /*HANDLE CASE FOR CUT-INS */
                                                    !Object.is(entityAffected.isCutIn, null) && !Object.is(entityAffected.isCutIn, undefined) && !Object.is(entityAffected.isCutIn, '') ?
                                                        !Object.is(entityAffected.tripId, null) && !Object.is(entityAffected.tripId, undefined) && !Object.is(entityAffected.tripId, '') ?
                                                            <li key={index4} className="result">
                                                                {entityAffected.tripHeadsign} Trip Departure
                                                                {
                                                                    Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span>
                                                                        : <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>
                                                                }
                                                                {
                                                                    Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ? <span></span>
                                                                        : <span>, Stop {entityAffected.stopCode}, {entityAffected.stopName}{entityAffected.cutInEndDisplay}</span>
                                                                }
                                                            </li>
                                                        : <span></span>
                                                    :
                                                    /*HANDLE NORMAL DATA */
                                                    Object.is(entityAffected.tripId, null) || Object.is(entityAffected.tripId, undefined) || Object.is(entityAffected.tripId, '') ?
                                                        <li key={index4} className="result">{Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ?
                                                                <span className="empty"></span> : <span>Stop {entityAffected.stopCode}, {entityAffected.stopName} {Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span> : <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>}</span>}
                                                        </li> :
                                                        <li key={index4} className="result">
                                                            {entityAffected.tripHeadsign} Trip Departure
                                                            {Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span>
                                                                    : <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>}
                                                            {Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ?
                                                                <span></span>
                                                                : <span>, Stop {entityAffected.stopCode}, {entityAffected.stopName} </span>}
                                                        </li>
                                                ))
                                                }
                                            </ul>
                                        : <span></span>
                                    }                                      
                                </div>
                            </CardBody>
                        </Card>
                    )}
            </div>
        )
    }
}

export class ManualAlert extends Component {
    static renderManualAlertCard(noEndDateTime, alert, index) {
        function insertSpaces(string) {
            string = string.replace(/([a-z])([A-Z])/g, '$1 $2');
            string = string.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
            return string;
        }

        function evaluateFutureAlertDetails(alert) {
            let detailTitle = "Click for details";
            for (let index = 0; index < alert.alertPeriods.length; index++) {
                if (moment.utc(alert.alertPeriods[index].startDateTime).local().format('yyyy-MM-DDTHH:mm:ss') > moment.utc().local().format('yyyy-MM-DDTHH:mm:ss')) {
                    detailTitle = "Click for details (Future Alert)"; 
                    break;
                }
            }
            
            return detailTitle;
        }

        function evaluateFutureAlert(alert) {
            let isFuture = false;
            for (let index = 0; index < alert.alertPeriods.length; index++) {
                if (moment.utc(alert.alertPeriods[index].startDateTime).local().format('yyyy-MM-DDTHH:mm:ss') > moment.utc().local().format('yyyy-MM-DDTHH:mm:ss')) {
                    isFuture = true;
                    return <Badge className="alertsBadgeDetailFuture" pill>{insertSpaces(alert.effectText)}</Badge>
                } else {
                    // Do Nothing
                }
            }
            if (!isFuture) {
                return <Badge className="alertsBadgeDetail" pill > {insertSpaces(alert.effectText)}</Badge>
            }
        }

        return (
            <Card key={index} className="mb-3"> 
                <CardBody>
                    <CardTitle tag="h5">
                        <Link to={{ pathname: 'RouteAlertsDetail/' + alert.transitSystemId + '/' + alert.routeId + '/' + alert.uniqueId }} key={alert.id} title={evaluateFutureAlertDetails(alert)}>{evaluateFutureAlert(alert)}</Link>
                    </CardTitle>
                    <CardSubtitle className="mb-2 text-muted" tag="h6">
                        <div>In Effect:</div>
                        {!Object.is(alert.alertPeriods, null) && !Object.is(alert.alertPeriods, undefined) ?
                            alert.alertPeriods.map((activePeriod, index3) =>
                            (
                                Object.is(activePeriod.endDateTime, null) || Object.is(activePeriod.endDateTime, undefined) || Object.is(activePeriod.endDateTime, noEndDateTime) ?
                                    <div key={index3}>{moment.utc(activePeriod.startDateTime).local().format('LLL')}</div> :
                                    <div key={index3}>{moment.utc(activePeriod.startDateTime).local().format('LLL')} to {moment.utc(activePeriod.endDateTime).local().format('LLL')}</div>
                            )
                        ) : <span></span>}
                        {!Object.is(alert.alertHeaders, null) && !Object.is(alert.alertHeaders, undefined) ? alert.alertHeaders.map((header, index5) => <div key={index5} dangerouslySetInnerHTML={{ __html: header.text }} />) : <span></span>}
                        {!Object.is(alert.alertDescriptions, null) && !Object.is(alert.alertDescriptions, undefined) ? alert.alertDescriptions.map((description, index2) => <div key={index2} dangerouslySetInnerHTML={{ __html: description.text }} />) : <span></span>}
                    </CardSubtitle>
                    <div className="card-text">                       
                        {!Object.is(alert.alertEntityAffecteds, null) && !Object.is(alert.alertEntityAffecteds, undefined) ?
                            <ul className="alertEntityAffectedsUl">
                                {
                                    alert.alertEntityAffecteds.map((entityAffected, index4) =>
                                    (
                                        Object.is(entityAffected.tripId, null) || Object.is(entityAffected.tripId, undefined) || Object.is(entityAffected.tripId, '') ?
                                            <li key={index4} className="result">
                                                {Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ?
                                                    <span className="empty"></span> : <span>Stop {entityAffected.stopCode}, {entityAffected.stopName} {Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span> :
                                                        <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>}</span>}
                                            </li> :
                                            <li key={index4} className="result">
                                                {entityAffected.tripHeadsign} Trip Departure {Object.is(entityAffected.departureTime, null) || Object.is(entityAffected.departureTime, undefined) || Object.is(entityAffected.departureTime, '') ? <span></span> : <span> @{moment(entityAffected.departureTime, 'HH:mm').format('LT')}</span>}
                                                {Object.is(entityAffected.stopId, null) || Object.is(entityAffected.stopId, undefined) || Object.is(entityAffected.stopId, '') ? <span></span> :
                                                    <span>, Stop {entityAffected.stopCode}, {entityAffected.stopName} </span>}
                                            </li>
                                        )
                                    )
                                }
                            </ul> : <span></span>}
                    </div>
                </CardBody>
            </Card>
        );
    }

    static showAlertCount(alertCssId, numAlerts) {
        const element = document.getElementById(alertCssId);
        if (element === null) {
            return;
        }
        element.textContent = `${numAlerts} Alerts`;
        element.className = "alertsBadge badge bg-secondary rounded-pill";
    }

    render() {
        const noEndDateTime = '1970-01-01T00:00:00';

        //cast all false values to 0 
        const numInformationServiceAlerts = this.props.informationServiceAlerts?.length || 0;
        const numStationAccessAlerts = this.props.stationAccessAlerts?.length || 0;
        const numHandyDartAlerts = this.props.handyDartAlerts?.length || 0;
        const numHealthConnectionsAlerts = this.props.healthConnectionsAlerts?.length || 0;

        if (numInformationServiceAlerts > 0) {
            //ManualAlert.showAlertCount("alertCountInformationServices", numInformationServiceAlerts);
            return (<div>{this.props.informationServiceAlerts.map((alert, index) => ManualAlert.renderManualAlertCard(noEndDateTime, alert, index))}</div>)
        }
        else if (numStationAccessAlerts > 0) {
            //ManualAlert.showAlertCount("alertCountStationAccess", numStationAccessAlerts);
            return (<div>{this.props.stationAccessAlerts.map((alert, index) => ManualAlert.renderManualAlertCard(noEndDateTime, alert, index))}</div>);
        }
        else if (numHandyDartAlerts > 0) {
            //ManualAlert.showAlertCount("alertCountHandyDart", numHandyDartAlerts);
            return (<div>{this.props.handyDartAlerts.map((alert, index) => ManualAlert.renderManualAlertCard(noEndDateTime, alert, index))}</div>);
        }
        else if (numHealthConnectionsAlerts > 0) {
            //ManualAlert.showAlertCount("alertCountHealthConnections", numHealthConnectionsAlerts);
            return (<div>{this.props.healthConnectionsAlerts.map((alert, index) => ManualAlert.renderManualAlertCard(noEndDateTime, alert, index))}</div>);
        }
        return (<div>There are no Alerts or Advisories at this time.</div>);
    }
}

export class Alert extends Component {
    //static displayName = Alert.name;
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = { isAuthenticated: false, userName: null, userId: null, myRouteAlerts: [], subscriptions: [], activeTab: 0, localBCDateTime: null, dateTimeFormatStart: "", dateTimeFormatEnd: "", selectedNavLink: "1", activeNavLink: "1", activeAlertTypes: props.activeAlertTypes, selectedTransitSystemOption: '', transitSystems: [], routealerts: [], informationServiceAlerts: [], stationAccessAlerts: [], handyDartAlerts: [], healthConnectionsAlerts: [], loadingRoutes: true, loadingInformationServices: true, loadingStationAccess: true, loadingHandyDart: true, loadingHealthConnections: true, open: false, error: false };
        this.populateLocalDateTime();
        this.populateLogin();
        this.handleClickActiveTab = this.handleClickActiveTab.bind(this);
    }

    handleClickActiveTab(currentTab) {
        this.setState({ activeTab: currentTab });
    }

    getCookieValue() {
        const name = 'BCTransitCurrentSystem';
        const consentName = 'BCTransitCookieConsent';
        const consentCookie = Cookies.get(consentName);

        //failsafe. If consent cookie doesn't exist, clear out other cookie
        if (consentCookie === null || consentCookie === undefined) {
            const cookie = Cookies.get(name);
            if (cookie !== null && cookie !== undefined) {
                //if it exists, delete it, and recreate
                Cookies.remove(name);
            }
        }

        if (consentCookie !== null && consentCookie !== undefined) {
            if (consentCookie === 'true') {
                const cookie = Cookies.get(name);
                if (cookie == null || cookie === undefined) {
                    //if it doesn't exist, create it
                    Cookies.set(name, '', { expires: 30 });
                    this.setState({ selectedTransitSystemOption: '' });
                }
                else {
                    this.setState({ selectedTransitSystemOption: cookie });
                }
            }
        }
    }

    setCookieValue(value) {
        const name = 'BCTransitCookieConsent';
        const consentCookie = Cookies.get(name);
        if (consentCookie !== null && consentCookie !== undefined) {
            if (consentCookie === 'true') {
                const name = 'BCTransitCurrentSystem';
                const cookie = Cookies.get(name);
                if (cookie !== null && cookie !== undefined) {
                    //if it exists, delete it, and recreate
                    Cookies.remove(name);
                }
                //if it doesn't exist, create it
                Cookies.set(name, value, { expires: 30 });
            }
            else {
                this.setState({ selectedTransitSystemOption: value });
            }
        }
    }

    alertTypeIsActive = alertTypeId => this.state?.activeAlertTypes?.some(el => el.id === alertTypeId) || false;

    // Route names are inconsistent across regions - if there's a / in the route name add spaces on either side if none exist
    // ie UVic / Downtown is fine, but Victoria/5th needs to be changed to Victoria / 5th
    static padSlashWithSpaces(routeName) {
        const fixedRouteName = routeName.split("/").map(substr => ` ${substr.trim()} `);
        return fixedRouteName.join("/").trim();
    }

    componentDidMount() {
        this._isMounted = true;
        //console.log('Mounted=' + this._isMounted);

        //if the user has come here before and selected a transit system, that value is stored in a cookie
        this.getCookieValue();
        const name = 'BCTransitCurrentSystem';
        var value = Cookies.get(name);
        if (value === '') {
            //no transit system selected/defined
            //alert('No Transit Systems available');
            return;
        }

        if (this._isMounted) {
            //alert(JSON.stringify(this.props.activeAlertTypes));
            this.populateActiveAlerts(value);
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        //console.log('UnMounted='+ this._isMounted);
    }

    TransitSystemToHomeEvent = (transitSystemId) => {
        //Reset alert counts when transit system is changed
        if (this.state.activeTab === 0) {
            Alert.displayNoAlerts("alertCount");
            Alert.displayNoAlerts("alertCountInformationServices");
            Alert.displayNoAlerts("alertCountHandyDart");
            Alert.displayNoAlerts("alertCountHealthConnections");
            Alert.displayNoAlerts("alertCountStationAccess");
        } else if (this.state.activeTab === 1) {
            Alert.displayNoAlerts("todaysAlertCount");
            Alert.displayNoAlerts("todaysAlertCountInformationServices");
            Alert.displayNoAlerts("todaysAlertCountHandyDart");
            Alert.displayNoAlerts("todaysAlertCountHealthConnections");
            Alert.displayNoAlerts("todaysAlertCountStationAccess");
        } else if (this.state.activeTab === 2) {
            Alert.displayNoAlerts("myAlertCount");
            Alert.displayNoAlerts("myAlertCountInformationServices");
            Alert.displayNoAlerts("myAlertCountHandyDart");
            Alert.displayNoAlerts("myAlertCountHealthConnections");
            Alert.displayNoAlerts("myAlertCountStationAccess");
        }

        this.setCookieValue(transitSystemId);
        this.setState({ selectedTransitSystemOption: transitSystemId });
        this.populateActiveAlerts(transitSystemId);

        //hide the offcanvas element in the transitsystem module
        if (document.getElementsByClassName("btn-close")[0] != null) {
            document.getElementsByClassName("btn-close")[0].click();
        }
    };

    handleRealtimeEvent = (transitSystemId) => {
        //Only update if it's the current selected TransitId
        if (transitSystemId === parseInt(this.state.selectedTransitSystemOption)) {
            this.populateRouteAlertData(transitSystemId);
        }
    };

    populateActiveAlerts(transitSystemId) {
        if (this.alertTypeIsActive(5)) {
            this.populateRouteAlertData(transitSystemId);
        }
        if (this.alertTypeIsActive(3)) {
            this.populateInformationServiceAlertData(transitSystemId, 3);
        }
        if (this.alertTypeIsActive(4)) {
            this.populateStationAccessData(transitSystemId, 4);
        }
        if (this.alertTypeIsActive(2)) {
            this.populateHandyDartData(transitSystemId, 2);
        }
        if (this.alertTypeIsActive(1)) {
            this.populateHealthConnectionsData(transitSystemId, 1);
        }
    }

    static displayAlertsCount(itemId, numAlerts) {
        const element = document.getElementById(itemId);
        if (element === null) {
            return;
        }

        element.textContent = `${numAlerts} Alerts`;
        element.className = "alertsBadge badge bg-secondary rounded-pill";
    }

    static displayNoAlerts(itemId) {
        const element = document.getElementById(itemId);
        if (element === null) {
            return;
        }
        element.textContent = "No Alerts";
        element.className = "alertsNoneBadge badge bg-secondary rounded-pill";
    }

    static renderRouteContents(routealerts, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        //get the total number of alerts in the system (for every route) and populate the parent 'Bus' accordion header
        const count = routealerts.reduce(((acc, curr) => acc + curr.alerts.length), 0);
        count > 0 ? Alert.displayAlertsCount("alertCount", count) : Alert.displayNoAlerts("alertCount");

        if (routealerts.length === 0) {
            return (<div>There are no Routes defined for this transit system.</div>);
        }
            
        function formatCardTitle(routeShortName) {
            if (!isNaN(routeShortName) && routeShortName < 0) {
                return "All";
            }
            else {
                return routeShortName;
            }
        }

        /*function formatFutureAlerts(route) {
            let isFuture = false;
            if (route.alerts != null && route.alerts.length > 0) {
                for (let index = 0; index < route.alerts.length; index++) {
                    if (route.alerts[index].alertPeriods.length > 0) {
                        for (let timeframe = 0; timeframe < route.alerts[index].alertPeriods.length; timeframe++) {
                            if (moment.utc(route.alerts[index].alertPeriods[timeframe].startDateTime).local().format(dateTimeFormatStart) > currentTime) {
                                isFuture = true;
                            } else {
                                // doNothing
                            }
                        }
                    }
                }
                return isFuture ? <Badge pill className="alertsBadgeFuture">{route.alerts.length} Alerts</Badge> : <Badge pill className="alertsBadge">{route.alerts.length} Alerts</Badge>
            } else {
                return <Badge pill className="alertsNoneBadge">No Alerts</Badge>
            }
        }*/

        return (
            <div>
                {
                    routealerts.map((route, index) =>                                         
                        <UncontrolledAccordion key={index.toString()} open={open}>
                            <AccordionItem>
                                <AccordionHeader targetId={index.toString()} title={route.routeLongName}>
                                    <div className="routeNumber">{formatCardTitle(route.routeShortName)}</div>
                                    <div className="routeName">{Alert.padSlashWithSpaces(route.routeLongName)}</div>
                                    <div className="routeAlert">
                                        {route.alerts != null && route.alerts.length > 0 ? <Badge pill className="alertsBadge">{route.alerts.length} Alerts</Badge> : <Badge pill className="alertsNoneBadge">No Alerts</Badge>}
                                    </div>
                                </AccordionHeader>
                                <AccordionBody accordionId={index.toString()}>
                                    <RouteAlert routeAlerts={route.alerts} routeId={route.routeShortName} />
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>                       
                    )
                }
            </div>
        );
    }

    static renderTodaysContents(routealerts, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        //get the total number of alerts in the system (for every route) and populate the parent 'Bus' accordion header
        //Filter the alerts by removing all alerts that are not between todays date
        //const currentTime = new Date().toISOString();
        
        const todaysroutes = JSON.parse(JSON.stringify(routealerts));
        for (let alerts in todaysroutes) {
            let isToday = false;
            if (todaysroutes[alerts].alerts.length > 0) {
                for (let index = 0; index < todaysroutes[alerts].alerts.length; index++) {
                    for (let timeframe in todaysroutes[alerts].alerts[index].alertPeriods) {
                        if ((moment.utc(todaysroutes[alerts].alerts[index].alertPeriods[timeframe].startDateTime).local().format(dateTimeFormatStart) <= currentTime &&
                            moment.utc(todaysroutes[alerts].alerts[index].alertPeriods[timeframe].endDateTime).local().format(dateTimeFormatEnd) >= currentTime)
                            || todaysroutes[alerts].alerts[index].alertPeriods[timeframe].endDateTime === null
                            || todaysroutes[alerts].alerts[index].alertPeriods[timeframe].endDateTime === undefined
                            || todaysroutes[alerts].alerts[index].alertPeriods[timeframe].endDateTime === noEndDateTime) {
                            isToday = true;
                            break;
                        }
                    }
                    if (todaysroutes[alerts].alerts.length > 1 && !isToday) {
                        todaysroutes[alerts].alerts.splice(index, 1);
                        index--;
                    } else if (todaysroutes[alerts].alerts.length === 1 && !isToday) {
                        todaysroutes[alerts].alerts.splice(0, 1);
                        index--;
                    }
                    isToday = false;
                }
            }
        }

        const count = todaysroutes.reduce(((acc, curr) => acc + curr.alerts.length), 0);
        count > 0 ? Alert.displayAlertsCount("todaysAlertCount", count) : Alert.displayNoAlerts("todaysAlertCount");

        if (todaysroutes.length === 0) {
            return (<div>There are no Routes defined for this transit system.</div>);
        }

        function formatCardTitle(routeShortName) {
            if (!isNaN(routeShortName) && routeShortName < 0) {
                return "All";
            }
            else {
                return routeShortName;
            }
        }

        return (
            <div>
                {
                    todaysroutes.map((route, index) =>
                        <UncontrolledAccordion key={index.toString()} open={open}>
                            <AccordionItem>
                                <AccordionHeader targetId={index.toString()} title={route.routeLongName}>
                                    <div className="routeNumber">{formatCardTitle(route.routeShortName)}</div>
                                    <div className="routeName">{Alert.padSlashWithSpaces(route.routeLongName)}</div>
                                    <div className="routeAlert">
                                        {route.alerts != null && route.alerts.length > 0 ? <Badge pill className="alertsBadge">{route.alerts.length} Alerts</Badge> : <Badge pill className="alertsNoneBadge">No Alerts</Badge>}
                                    </div>
                                </AccordionHeader>
                                <AccordionBody accordionId={index.toString()}>
                                    <RouteAlert routeAlerts={route.alerts} routeId={route.routeShortName} />
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                    )
                }
            </div>
        );
    }

    static renderMyAccountContents(routealerts, subscriptions, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        //get the total number of alerts in the system (for every route) and populate the parent 'Bus' accordion header
        //Filter alerts based on if the routeid exists in subscriptions
        const allAlertsShort = "-999999";
        const myAlerts = [];
        let newfilter = [];
        let filterSubscriptions = subscriptions.map(data => data.routeId);
        let filteredTransitSystem = subscriptions.map(data => data.transitSystemTitle);

        /*
        const myAlerts = routealerts.filter((data) => {
            return filterSubscriptions.includes(data.routeId) || data.routeId === "-999999";
        });*/

        for (let index = 0; index < routealerts.length; index++) {
            if (filterSubscriptions.includes(routealerts[index].routeId)) {
                myAlerts.push(routealerts[index]);
                for (let route in filterSubscriptions) {
                    if (filterSubscriptions[route] === routealerts[index].routeId) {
                        newfilter.push(filteredTransitSystem[route]);
                        break;
                    }
                }
            } else if (routealerts[index].routeId === allAlertsShort) {
                myAlerts.push(routealerts[index]);
                for (let name in routealerts[index].alerts) {
                    newfilter.push(routealerts[index].alerts[name].transitSystemName);
                }
            }
            else {
                // should never get here - do nothing
            }
        }

        /*function formatFutureAlerts(route) {
            let isFuture = false;
            if (route.alerts != null && route.alerts.length > 0) {
                for (let i = 0; i < route.alerts.length; i++) {
                    if (route.alerts[i].alertPeriods.length > 0) {
                        for (let j = 0; j < route.alerts[i].alertPeriods.length; j++) {
                            if (moment.utc(route.alerts[i].alertPeriods[j].startDateTime).local().format(dateTimeFormatStart) > currentTime) {
                                isFuture = true;
                            }
                        }
                    }
                }
                return isFuture ? <Badge pill className="alertsBadgeFuture">{route.alerts.length} Alerts</Badge> : <Badge pill className="alertsBadge">{route.alerts.length} Alerts</Badge>
            } else {
                return <Badge pill className="alertsNoneBadge">No Alerts</Badge>
            }
        }*/

        const count = myAlerts.reduce(((acc, curr) => acc + curr.alerts.length), 0);
        count > 0 ? Alert.displayAlertsCount("myAlertCount", count) : Alert.displayNoAlerts("myAlertCount");

        if (myAlerts.length === 0) {
            return (
                <Card>
                    <CardBody>
                        <h5>My Alerts</h5>
                        <div>
                            You have no Alert Subscriptions defined. <a href="/Identity/Account/Manage/Subscriptions">Create an Alert Subscription</a> to see your Alerts on this page.
                        </div>
                    </CardBody>
                </Card>
            );
        }
        function formatCardTitle(routeShortName) {
            if (!isNaN(routeShortName) && routeShortName < 0) {
                return "All";
            }
            else {
                return routeShortName;
            }
        }

        return (
            <div>
                {
                    myAlerts.map((route, index) =>
                        <UncontrolledAccordion key={index.toString()} open={open}>
                            <AccordionItem>
                                <AccordionHeader targetId={index.toString()} title={route.routeLongName}>
                                    <div className="routeNumber">{formatCardTitle(route.routeShortName)}</div>
                                    <div className="routeName">{Alert.padSlashWithSpaces(route.routeLongName + " - " + newfilter[index])}</div>
                                    <div className="routeAlert">
                                        {route.alerts != null && route.alerts.length > 0 ? <Badge pill className="alertsBadge">{route.alerts.length} Alerts</Badge> : <Badge pill className="alertsNoneBadge">No Alerts</Badge>}
                                    </div>
                                </AccordionHeader>
                                <AccordionBody accordionId={index.toString()}>
                                    <RouteAlert routeAlerts={route.alerts} routeId={route.routeShortName} />
                                </AccordionBody>
                            </AccordionItem>
                        </UncontrolledAccordion>
                    )
                }
            </div>
        );
    }

    //All Information Services Content
    static renderInformationServiceContents(informationServiceAlerts, open) {
        return Alert.renderManualAlert("alertCountInformationServices", informationServiceAlerts, <ManualAlert informationServiceAlerts={informationServiceAlerts} />);
    }

    //Today's Information Services Content
    static renderTodaysInformationServiceContents(informationServiceAlerts, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        //const currentTime = new Date().toISOString();

        const todaysAlerts = informationServiceAlerts.filter(data => {
            for (let index = 0; index < data.alertPeriods.length; index++) {
                if ((moment.utc(data.alertPeriods[index].startDateTime).local().format(dateTimeFormatStart) <= currentTime) &&
                    (currentTime <= moment.utc(data.alertPeriods[index].endDateTime).local().format(dateTimeFormatEnd) || data.alertPeriods[index].endDateTime === null || data.alertPeriods[index].endDateTime === noEndDateTime || data.alertPeriods[index].endDateTime === undefined)) {
                    return data;
                }
                return null;
            }
            return null;
        });

        return Alert.renderManualAlert("todaysAlertCountInformationServices", todaysAlerts, <ManualAlert informationServiceAlerts={todaysAlerts} />);
    }

    //My Information Services Content
    static renderInformationServiceMyContents(informationServiceAlerts, subscriptions, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        let filterSubscriptions = subscriptions.map(data => data.routeId);

        const myAlerts = informationServiceAlerts.filter((data) => {
            for (let index = 0; index < data.alertEntityAffecteds.length; index++) {
                return data.alertEntityAffecteds.length > 0 ? filterSubscriptions.includes(data.alertEntityAffecteds[index].routeId) : null;
            }
            return null;
        });

        return Alert.renderManualAlert("myAlertCountInformationServices", myAlerts, <ManualAlert informationServiceAlerts={myAlerts} />);
    }

    //All Station Access Content
    static renderStationAccessContents(stationAccessAlerts, open) {
        return Alert.renderManualAlert("alertCountStationAccess", stationAccessAlerts, <ManualAlert stationAccessAlerts={stationAccessAlerts} />);
    }

    //Today's Station Access Content
    static renderStationAccessTodaysContents(stationAccessAlerts, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        //const currentTime = new Date().toISOString();

        const todaysAlerts = stationAccessAlerts.filter(data => {
            for (let index = 0; index < data.alertPeriods.length; index++) {
                if ((moment.utc(data.alertPeriods[index].startDateTime).local().format(dateTimeFormatStart) <= currentTime) &&
                    (currentTime <= moment.utc(data.alertPeriods[index].endDateTime).local().format(dateTimeFormatEnd) || data.alertPeriods[index].endDateTime === null || data.alertPeriods[index].endDateTime === noEndDateTime || data.alertPeriods[index].endDateTime === undefined)) {
                    return data;
                }
                else return null;
            }
            return null;
        });

        return Alert.renderManualAlert("todaysAlertCountStationAccess", todaysAlerts, <ManualAlert stationAccessAlerts={todaysAlerts} />);
    }

    //My Station Access Content
    static renderStationAccessMyContents(stationAccessAlerts, subscriptions, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        let filterSubscriptions = subscriptions.map(data => data.routeId);
        const myAlerts = stationAccessAlerts.filter((data) => {
            for (let index = 0; index < data.alertEntityAffecteds.length; index++) {
                return data.alertEntityAffecteds.length > 0 ? filterSubscriptions.includes(data.alertEntityAffecteds[index].routeId) : null;
            }
            return null;
        });

        return Alert.renderManualAlert("myAlertCountStationAccess", myAlerts, <ManualAlert stationAccessAlerts={myAlerts} />);
    }

    //All Handy Dart Content
    static renderHandyDartContents(handyDartAlerts, open) {
        return Alert.renderManualAlert("alertCountHandyDart", handyDartAlerts, <ManualAlert handyDartAlerts={handyDartAlerts} />);
    }

    //Today's Handy Dart Content
    static renderHandyDartTodaysContents(handyDartAlerts, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        //const currentTime = new Date().toISOString();
       
        const todaysAlerts = handyDartAlerts.filter(data => {
            for (let index = 0; index < data.alertPeriods.length; index++) {
                if ((moment.utc(data.alertPeriods[index].startDateTime).local().format(dateTimeFormatStart) <= currentTime) &&
                    (currentTime <= moment.utc(data.alertPeriods[index].endDateTime).local().format(dateTimeFormatEnd) || data.alertPeriods[index].endDateTime === null || data.alertPeriods[index].endDateTime === noEndDateTime || data.alertPeriods[index].endDateTime === undefined)) {
                    return data;
                }
                else return null;
            }
            return null;
        });

        return Alert.renderManualAlert("todaysAlertCountHandyDart", todaysAlerts, <ManualAlert handyDartAlerts={todaysAlerts} />);
    }

    //All Handy Dart Content
    static renderHandyDartMyContents(handyDartAlerts, subscriptions, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        let filterSubscriptions = subscriptions.map(data => data.routeId);
        const myAlerts = handyDartAlerts.filter((data) => {
            for (let index = 0; index < data.alertEntityAffecteds.length; index++) {
                return data.alertEntityAffecteds.length > 0 ? filterSubscriptions.includes(data.alertEntityAffecteds[index].routeId) : null;
            }
            return null;
        });

        return Alert.renderManualAlert("myAlertCountHandyDart", myAlerts, <ManualAlert handyDartAlerts={myAlerts} />);
    }

    //All Health Connections Content
    static renderHealthConnectionsContents(healthConnectionsAlerts, open) {
        return Alert.renderManualAlert("alertCountHealthConnections", healthConnectionsAlerts, <ManualAlert healthConnectionsAlerts={healthConnectionsAlerts} />);
    }

    //Today's Health Connections Content
    static renderHealthConnectionsTodaysContents(healthConnectionsAlerts, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        //const currentTime = new Date().toISOString();

        const todaysAlerts = healthConnectionsAlerts.filter(data => {
            for (let index = 0; index < data.alertPeriods.length; index++) {
                if ((moment.utc(data.alertPeriods[index].startDateTime).local().format(dateTimeFormatStart) <= currentTime) &&
                    (currentTime <= moment.utc(data.alertPeriods[index].endDateTime).local().format(dateTimeFormatEnd) || data.alertPeriods[index].endDateTime === null || data.alertPeriods[index].endDateTime === noEndDateTime || data.alertPeriods[index].endDateTime === undefined)) {
                    return data;
                }
                else return null;
            }
            return null;
        });

        return Alert.renderManualAlert("todaysAlertCountHealthConnections", todaysAlerts, <ManualAlert healthConnectionsAlerts={todaysAlerts} />);
    }

    //My Health Connections Content
    static renderHealthConnectionsMyContents(healthConnectionsAlerts, subscriptions, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, open) {
        let filterSubscriptions = subscriptions.map(data => data.routeId);
        const myAlerts = healthConnectionsAlerts.filter((data) => {
            for (let index = 0; index < data.alertEntityAffecteds.length; index++) {
                return data.alertEntityAffecteds.length > 0 ? filterSubscriptions.includes(data.alertEntityAffecteds[index].routeId) : null;
            }
            return null;
        });

        return Alert.renderManualAlert("myAlertCountHealthConnections", myAlerts, <ManualAlert healthConnectionsAlerts={myAlerts} />);
    }


    static renderManualAlert(alertCssId, alerts, manualAlertJsx) {
        let count = alerts.length
        count > 0 ? Alert.displayAlertsCount(alertCssId, count) : Alert.displayNoAlerts(alertCssId);
        
        if (alerts.length > 0) {
            return (<div>{manualAlertJsx}</div>);
        }

        return (<div>There are no Alerts for this transit system.</div>);
    }

    static renderAlertType(alertTypeId, name, content, imageSrc, imageAlt, alertCountId) {
        return (
            <AccordionItem className="routeAccordionItem" key={alertTypeId.toString()}>
                <AccordionHeader targetId={alertTypeId.toString()} title={name}>
                    <div className="routeIcon"><img src={imageSrc} alt={imageAlt} height="40" width="40" /></div>
                    <div className="routeType">{name}</div>
                    <div className="routeAlert"><Badge id={alertCountId} className="alertsBadgeDetail" pill></Badge></div>
                </AccordionHeader>
                <AccordionBody accordionId={alertTypeId.toString()}>
                    {content}
                </AccordionBody>
            </AccordionItem>
        );
    }

    renderAlertTypeContentsIfActive(alertTypeId, loadingKey, contentKey, loadingText, renderFunction, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd) {
        if (!this.alertTypeIsActive(alertTypeId)) {
            return "";
        }
        if (this.state.selectedTransitSystemOption === '') {
            loadingText = "Select a Transit System to View Alerts";
        }
        if (this.state[loadingKey]) {
            return <div className="card"><div className="card-body"><em>{loadingText}</em></div></div>;
        }

        return renderFunction(this.state[contentKey], currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, this.state.open);
    }

    renderMyAlertTypeContentsIfActive(alertTypeId, loadingKey, contentKey, subscriptionKey, loadingText, renderFunction, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd) {
        if (!this.alertTypeIsActive(alertTypeId)) {
            return "";
        }
        if (this.state.selectedTransitSystemOption === '') {
            loadingText = "Select a Transit System to View Alerts";
        }
        if (this.state[loadingKey]) {
            return <div className="card"><div className="card-body"><em>{loadingText}</em></div></div>;
        }

        return renderFunction(this.state[contentKey], this.state[subscriptionKey], currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd, this.state.open);
    }

    render() {
        const currentTime = this.state.localBCDateTime;
        const dateTimeFormatStart = this.state.dateTimeFormatStart;
        const dateTimeFormatEnd = this.state.dateTimeFormatEnd;
        const noEndDateTime = '1970-01-01T00:00:00';
        
        let routeAlertContents = this.renderAlertTypeContentsIfActive(5, "loadingRoutes", "routealerts", "Loading Alerts and Advisories...", Alert.renderRouteContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let routeAlertTodaysContents = this.renderAlertTypeContentsIfActive(5, "loadingRoutes", "routealerts", "Loading Alerts and Advisories...", Alert.renderTodaysContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let routeAlertMyAccountContents = this.renderMyAlertTypeContentsIfActive(5, "loadingRoutes", "myRouteAlerts", "subscriptions", "Loading Alerts and Advisories...", Alert.renderMyAccountContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let handyDartContents = this.renderAlertTypeContentsIfActive(2, "loadingHandyDart", "handyDartAlerts", "Loading Handy Dart Alerts...", Alert.renderHandyDartContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let handyDartTodaysContents = this.renderAlertTypeContentsIfActive(2, "loadingHandyDart", "handyDartAlerts", "Loading Handy Dart Alerts...", Alert.renderHandyDartTodaysContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);

        /* CURRENTLY CANNOT SUBSCRIBE TO THESE PARTICULAR CATEGORIES, COMMENTED OUT ON PURPOSE */
        //let handyDartMyContents = this.renderMyAlertTypeContentsIfActive(2, "loadingHandyDart", "handyDartAlerts", "subscriptions", "Loading Handy Dart Alerts...", Alert.renderHandyDartMyContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let informationServiceContents = this.renderAlertTypeContentsIfActive(3, "loadingInformationServices", "informationServiceAlerts", "Loading Information Service Alerts", Alert.renderInformationServiceContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let informationServiceTodaysContents = this.renderAlertTypeContentsIfActive(3, "loadingInformationServices", "informationServiceAlerts", "Loading Information Service Alerts", Alert.renderTodaysInformationServiceContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);

        /* CURRENTLY CANNOT SUBSCRIBE TO THESE PARTICULAR CATEGORIES, COMMENTED OUT ON PURPOSE */
        //let informationServiceMyContents = this.renderMyAlertTypeContentsIfActive(3, "loadingInformationServices", "informationServiceAlerts", "subscriptions", "Loading Information Service Alerts", Alert.renderInformationServiceMyContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let stationAccessContents = this.renderAlertTypeContentsIfActive(4, "loadingStationAccess", "stationAccessAlerts", "Loading Bus Exchange Alerts...", Alert.renderStationAccessContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let stationAccessTodaysContents = this.renderAlertTypeContentsIfActive(4, "loadingStationAccess", "stationAccessAlerts", "Loading Bus Exchange Alerts...", Alert.renderStationAccessTodaysContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);

        /* CURRENTLY CANNOT SUBSCRIBE TO THESE PARTICULAR CATEGORIES, COMMENTED OUT ON PURPOSE */
        //let stationAccessMyContents = this.renderMyAlertTypeContentsIfActive(4, "loadingStationAccess", "stationAccessAlerts", "subscriptions", "Loading Bus Exchange Alerts...", Alert.renderStationAccessMyContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let healthConnectionsContents = this.renderAlertTypeContentsIfActive(1, "loadingHealthConnections", "healthConnectionsAlerts", "Loading Health Connections Alerts...", Alert.renderHealthConnectionsContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);
        let healthConnectionsTodaysContents = this.renderAlertTypeContentsIfActive(1, "loadingHealthConnections", "healthConnectionsAlerts", "Loading Health Connections Alerts...", Alert.renderHealthConnectionsTodaysContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);

        /* CURRENTLY CANNOT SUBSCRIBE TO THESE PARTICULAR CATEGORIES, COMMENTED OUT ON PURPOSE */
        //let healthConnectionsMyContents = this.renderMyAlertTypeContentsIfActive(1, "loadingHealthConnections", "healthConnectionsAlerts", "subscriptions", "Loading Health Connections Alerts...", Alert.renderHealthConnectionsMyContents, currentTime, noEndDateTime, dateTimeFormatStart, dateTimeFormatEnd);

        const activeClass = 'active';
        const disabledClass = 'disabled';
        const notActiveClass = 'notActive';

        return (
            <div className="container-flex">
                <div id="homeRoot" className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
                    <h1 className="home-page-title">Alerts and Advisories</h1>
                </div>
                <div className="mb-4 mt-4">
                    <div className="fw-bold">
                        <h2>Transit System</h2>
                    </div>
                    <div>
                        <TransitSystem HomeToTransitSystemEvent={this.state.selectedTransitSystemOption} handleCallBack={this.TransitSystemToHomeEvent} />
                    </div>
                </div>
                <div>
                    <Nav tabs fill pills horizontal='center'>
                        <NavItem>
                            <NavLink
                                id="allAlerts"
                                title="Click to view All Alerts"
                                className={this.state.activeTab === 0 ? activeClass : '' || this.state.loadingRoutes === true ? disabledClass : notActiveClass}
                                onClick={this.handleClickActiveTab.bind(this, 0)}
                            >All Alerts</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                id="todaysAlerts"
                                title="Click to view Today's Alerts"
                                className={this.state.activeTab === 1 ? activeClass : '' || this.state.loadingRoutes === true ? disabledClass : notActiveClass}
                                onClick={this.handleClickActiveTab.bind(this, 1)}
                            >Today's Alerts</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                id="myAlerts"
                                title="Click to view My Alerts"
                                className={this.state.activeTab === 2 ? activeClass : '' || this.state.loadingRoutes === true ? disabledClass : notActiveClass}
                                onClick={this.handleClickActiveTab.bind(this, 2)}
                            >My Alerts</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.activeTab.toString()}>
                        <TabPane tabId="0">
                            <div>                              
                                <UncontrolledAccordion defaultOpen="5">
                                    {this.alertTypeIsActive(5) && Alert.renderAlertType("5", "Bus", routeAlertContents, "/images/bus-icon.png", "Bus", "alertCount")}
                                    {this.alertTypeIsActive(2) && Alert.renderAlertType("2", "handyDART", handyDartContents, "/images/bus-icon.png", "Bus", "alertCountHandyDart")}
                                    {this.alertTypeIsActive(3) && Alert.renderAlertType("3", "Information Services", informationServiceContents, "/images/bus-icon.png", "Bus", "alertCountInformationServices")}
                                    {this.alertTypeIsActive(4) && Alert.renderAlertType("4", "Bus Exchange", stationAccessContents, "/images/bus-icon.png", "Bus", "alertCountStationAccess")}
                                    {this.alertTypeIsActive(1) && Alert.renderAlertType("1", "Health Connections", healthConnectionsContents, "/images/bus-icon.png", "Bus", "alertCountHealthConnections")}
                                </UncontrolledAccordion>                            
                            </div>
                        </TabPane>
                        <TabPane tabId="1">
                            <div>                             
                                <UncontrolledAccordion defaultOpen="5">
                                    {this.alertTypeIsActive(5) && Alert.renderAlertType("5", "Bus", routeAlertTodaysContents, "/images/bus-icon.png", "Bus", "todaysAlertCount")}
                                    {this.alertTypeIsActive(2) && Alert.renderAlertType("2", "handyDART", handyDartTodaysContents, "/images/bus-icon.png", "Bus", "todaysAlertCountHandyDart")}
                                    {this.alertTypeIsActive(3) && Alert.renderAlertType("3", "Information Services", informationServiceTodaysContents, "/images/bus-icon.png", "Bus", "todaysAlertCountInformationServices")}
                                    {this.alertTypeIsActive(4) && Alert.renderAlertType("4", "Bus Exchange", stationAccessTodaysContents, "/images/bus-icon.png", "Bus", "todaysAlertCountStationAccess")}
                                    {this.alertTypeIsActive(1) && Alert.renderAlertType("1", "Health Connections", healthConnectionsTodaysContents, "/images/bus-icon.png", "Bus", "todaysAlertCountHealthConnections")}
                                </UncontrolledAccordion>                           
                            </div>
                        </TabPane>
                        <TabPane tabId="2">
                            <div>                             
                                {this.state.isAuthenticated ? (
                                    <UncontrolledAccordion defaultOpen="5">
                                        {
                                            this.alertTypeIsActive(5) && Alert.renderAlertType("5", "Bus", routeAlertMyAccountContents, "/images/bus-icon.png", "Bus", "myAlertCount")
                                        }
                                        {/* CURRENTLY CANNOT SUBSCRIBE TO THESE PARTICULAR CATEGORIES, COMMENTED OUT ON PURPOSE
                                            this.alertTypeIsActive(2) && Alert.renderAlertType("2", "handyDART", handyDartMyContents, "/images/bus-icon.png", "Bus", "myAlertCountHandyDart")}
                                            {this.alertTypeIsActive(3) && Alert.renderAlertType("3", "Information Services", informationServiceMyContents, "/images/bus-icon.png", "Bus", "myAlertCountInformationServices")}
                                            {this.alertTypeIsActive(4) && Alert.renderAlertType("4", "Bus Exchange", stationAccessMyContents, "/images/bus-icon.png", "Bus", "myAlertCountStationAccess")}
                                            {this.alertTypeIsActive(1) && Alert.renderAlertType("1", "Health Connections", healthConnectionsMyContents, "/images/bus-icon.png", "Bus", "myAlertCountHealthConnections")
                                        */}
                                    </UncontrolledAccordion>
                                ) : (
                                    <UncontrolledAccordion defaultOpen="5">
                                        <AccordionItem>
                                            <AccordionBody accordionId="5">
                                                <Card>
                                                    <CardBody>
                                                        <CardTitle tag="h5">My Alerts</CardTitle>
                                                        <p>
                                                            <a href="/authentication/login" title="Login">Login</a> to view your BC Transit Alerts.
                                                        </p>
                                                        <p>
                                                            Don't have an account? <a href="/Identity/Account/Register" title="Register Now">Register Now</a> for BC Transit Alerts to receive notifications by Email and/or Text Message about disruptions, updates, or changes to the transit services that you take.
                                                        </p>
                                                    </CardBody>
                                                </Card>
                                            </AccordionBody>
                                        </AccordionItem>
                                    </UncontrolledAccordion>
                                )}                            
                            </div>
                        </TabPane>
                    </TabContent>
                </div>
                <br />
                <RealTime handleRealtimeEvent={this.handleRealtimeEvent} />
            </div>
        );
    }

    async populateLocalDateTime() {     
        const response = await fetch('datetime');
        if (response.status === 200) {
            const result = await response.json();
            this.setState({ localBCDateTime: result.localBCDateTime, dateTimeFormatStart: result.dateTimeFormatStart, dateTimeFormastEnd: result.dateTimeFormatEnd });
        } else {
            this.setState({ error: true });
        }
    }

    async populateLogin() {
        const [isAuthenticated, user] = await Promise.all([authService.isAuthenticated(), authService.getUser()])

        if (isAuthenticated) {
            this.setState({
                isAuthenticated: isAuthenticated,
                userName: user && user.name,
                userId: user && user.sub
            });

            this.populateSubscriptions(this.state.userId);
        }
        else
        { 
            //clear the output of displaying alert subscriptions
            this.setState({ isAuthenticated: false, userName: null, userId: null, myRouteAlerts: [], subscriptions: [] });
        }
    }

    async populateMyRouteAlertData(id) {
        let myData = [];
        for (let i in id) {
            if (id[i] !== undefined && id[i] !== '') {
                this.setState({ loadingRoutes: true });
                if (id[i].toString() === this.state.selectedTransitSystemOption && this.state.routealerts.length !== 0) {
                    myData = myData.concat(this.state.routealerts)
                } else {
                    const response = await fetch('routealert?id=' + id[i]);
                    if (response.status === 200) {
                        const data = await response.json();
                        myData = myData.concat(data);
                    } else {
                        this.setState({ error: true });
                    }
                }
            }
        }
        this.setState({ myRouteAlerts: myData, loadingRoutes: false, open: false });
    }

    async populateSubscriptions(userId) {
        if (userId !== undefined && userId !== '') {
            const response = await fetch('myroutealert?id=' + userId);
            if (response.status === 200) {
                const result = await response.json();
                const mytransitsystems = [...new Set(result.map(data => data.transitSystemId))];
                this.populateMyRouteAlertData(mytransitsystems);
                this.setState({ subscriptions: result });
            } else {
                this.setState({ error: true });
            }
        }
    }

    async populateRouteAlertData(id) {
         if (id !== undefined && id !== '') {
            this.setState({ loadingRoutes: true });
            const response = await fetch('routealert?id=' + id);
            if (response.status === 200) {
                const data = await response.json();
                this.setState({ routealerts: data, loadingRoutes: false, open: false });
            } else {
                this.setState({ error: true });
            }
        }
    }

    async populateInformationServiceAlertData(id, alertTypeId) {
        if (id !== undefined && id !== '') {
            this.setState({ loadingInformationServices: true });
            const response = await fetch('manualalert?transitId=' + id + '&alertTypeId=' + alertTypeId);
            if (response.status === 200) {
                const data = await response.json();
                this.setState({ informationServiceAlerts: data, loadingInformationServices: false, open: false });
            } else {
                this.setState({ error: true });
            }
        }
    }

    async populateStationAccessData(id, alertTypeId) {
        if (id !== undefined && id !== '') {
            this.setState({ loadingStationAccess: true });
            const response = await fetch('manualalert?transitId=' + id + '&alertTypeId=' + alertTypeId);
             if (response.status === 200) {
                const data = await response.json();
                this.setState({ stationAccessAlerts: data, loadingStationAccess: false, open: false });
            } else {
                this.setState({ error: true });
            }
        }
    }

    async populateHandyDartData(id, alertTypeId) {
        if (id !== undefined && id !== '') {
            this.setState({ loadingHandyDart: true });
            const response = await fetch('manualalert?transitId=' + id + '&alertTypeId=' + alertTypeId);
            if (response.status === 200) {
                const data = await response.json();
                this.setState({ handyDartAlerts: data, loadingHandyDart: false, open: false });
            } else {
                this.setState({ error: true });
            }
        }
    }

    async populateHealthConnectionsData(id, alertTypeId) {
        if (id !== undefined && id !== '') {
            this.setState({ loadingHealthConnections: true });
            const response = await fetch('manualalert?transitId=' + id + '&alertTypeId=' + alertTypeId);
            if (response.status === 200) {
                const data = await response.json();
                this.setState({ healthConnectionsAlerts: data, loadingHealthConnections: false, open: false });
            } else {
                this.setState({ error: true });
            }
        }
    }
}
