
import React, { useState, useEffect } from 'react';
import { config } from '../config';
import { HubConnectionBuilder } from '@microsoft/signalr';

const RealTime = ({ handleRealtimeEvent }) => {
    const [connection, setConnection] = useState(null);

    useEffect(() => {
        let url = 'http://localhost:7142/api/';
        if (config.ENVIRONMENT === 'Development') {
            //local/dev
            url = 'http://localhost:7142/api/';
        }
        else if (config.ENVIRONMENT === 'Staging') {
            //staging
            url = 'https://casrealtimeservicedev.bctransit.com/api/';
        }
        else if (config.ENVIRONMENT === 'Production') {
            //production
            url = 'https://casrealtimeservice.bctransit.com/api/';
        }
        else {
            //unhandled
        }

        const newConnection = new HubConnectionBuilder()
            .withUrl(url, {
                withCredentials: false
            })
            .withAutomaticReconnect()
            .build();

        setConnection(newConnection);
    }, []);

    useEffect(() => {
        if (connection) {
            connection.start()
                .then(result => {
                    console.log('Connected!');

                    connection.on('newAlert', transitId => {
                        handleRealtimeEvent(transitId);
                    });               
                })
                .catch(e => console.log('Connection failed: ', e));
        }
    }, [connection, handleRealtimeEvent]);
    
    return (
        <div>
           
        </div>
    );
};

export default RealTime;
